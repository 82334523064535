(function () {
  'use strict';

  function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
  }

  function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
  }

  function _iterableToArrayLimit(arr, i) {
    if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"] != null) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
  }

  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;

    for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

    return arr2;
  }

  function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  /* eslint-plugin-disable angular/johnpapa */
  (function () {
    var isMobileSite = window.isMobileSite || false;
    /**
     * Updates search banner ad with given search term
     * @param  {string} searchTerm - Search term
     */

    var updateAdBanner = function updateAdBanner(searchTerm) {
      if (window.fireTopSearch) {
        $('.js-search-ad-banner').html('');
        window.fireTopSearch(searchTerm);
      }
    };

    var searchResultsApp = angular.module('searchResults', ['sharedService']);
    searchResultsApp.controller('searchController', ['$scope', '$sce', '$interpolate', '$window', 'searchSync', 'dataService', function ($scope, $sce, $interpolate, $window, searchSync, dataService) {
      $scope.contentImagesDirectory = $window.contentImagesDirectory;
      $scope.swapPriceLabel = wmUtil.swapPriceCultures.includes(wmUtil.cultureKey);
      $scope.cultureKey = wmUtil.cultureKey;
      $scope.cultureConfig = $window.searchCultureConfig;
      $scope.signedIn = $window.PageClientData.CustomerStatus; // toggle prices on/off
      // Search params

      $scope.sortBy = $scope.cultureConfig.sortBy[0].value;
      $scope.size = 10;
      $scope.from = 0;
      $scope.products = [];
      $scope.resultCount = 0;
      $scope.data = null;
      $scope.loading = true;
      $scope.docTypes = [];
      $scope.docType = 'web page';
      $scope.didYouMean = '';
      $scope.originalSpelling = '';
      $scope.hasTypo = false;
      $scope.customerPriceType = window.customerPriceType.toLowerCase();
      $scope.primaryPriceLabel = $scope.cultureConfig.priceLabels[$scope.customerPriceType];
      $scope.secondaryPriceLabel = $scope.cultureConfig.priceLabels.retail;
      $scope.productStoreBtn = $scope.cultureConfig.productStoreBtn;
      /**
       * Get current search term from searchSync service
       * @return {string} - Current search term
       */

      var queryTerms = searchSync.getQuery();
      $scope.searchTerm = queryTerms.searchTerm;
      $scope.category = queryTerms.category;
      /**
       * Get current category from searchSync service
       * @return {string} - Current search category
       */

      $scope.$on('searchTermUpdate', function (event, data) {
        // Use $apply only if digest cycle is not running
        if (!$scope.$$phase) {
          $scope.$apply(function () {
            $scope.searchTerm = data.searchTerm;
            $scope.category = data.category;
          });
        } else {
          $scope.searchTerm = data.searchTerm;
          $scope.category = data.category;
        }
      });
      /**
       * Watch searchTerm and category for changes, load new results on change
       */

      $scope.$watchCollection('[searchTerm, category]', function () {
        if ($scope.didYouMean.length) {
          $scope.hasTypo = true;
        } else {
          $scope.hasTypo = false;
          $scope.originalSpelling = '';
        }

        $scope.getSearchResults(true, true);
        updateAdBanner($scope.searchTerm);
      });
      $scope.$watch('docType', function (newValue, oldValue) {
        // Only fetch new search results if the docType has actually changed
        if (newValue !== oldValue) {
          $scope.getSearchResults(true, false);
        }
      });

      $scope.getDocTypeClass = function () {
        return 'search-results--' + $scope.docType.toLowerCase().replace(/\s/g, '-');
      };

      $scope.getTemplateUrl = function () {
        return dataService.getTemplateUrl($scope.docType);
      };

      $scope.interpolate = function (string) {
        return $interpolate(string)($scope);
      };

      $scope.addToCart = function (event) {
        var target = $(event.currentTarget);
        var cartID = isMobileSite ? 'btnShowMiniCart' : 'miniCartIcon';
        $window.AnimateAddToDest($get(target.attr('id')), cartID);
        $window.Melaleuca.Web.AjaxManager.AddProductToCart(target.data('sku'), target.siblings('input').val(), 'Site Search - Results', null, rtnAddToCart);
      };
      /**
       * Load next page of results
       */


      $scope.loadMoreResults = function () {
        $scope.from += $scope.size;
        $scope.getSearchResults();
      };
      /**
       * Scope function to handle fetching search results
       *
       * @param  {Boolean} shouldReload - Whether to load new result set
       */


      $scope.getSearchResults = function (shouldReload, shouldUpdateDocTypes) {
        shouldReload = shouldReload || false;
        shouldUpdateDocTypes = shouldUpdateDocTypes || false;

        if (shouldReload) {
          $scope.products = [];
          $scope.resultCount = 0;
          $scope.from = 0;
        }

        $scope.loading = true;
        fetchResults(function (dataResponse) {
          if (dataResponse.hits.hits.length > 0) {
            dataResponse.hits.hits = dataResponse.hits.hits.filter(function (obj) {
              // return only results for businesscenter or marketplace
              return /melaleuca.com\/(businesscenter|marketplace)/i.test(obj._source.url);
            });
            dataResponse.hits.hits = dataResponse.hits.hits.filter(function (e) {
              return e != null;
            });

            if (dataResponse.aggregations.all_docs.doc_type.buckets.length > 1) {
              dataResponse.hits.total = dataResponse.aggregations.all_docs.doc_type.buckets[1].doc_count = dataResponse.hits.hits.length;
            }
          }
          /* Google Tags (added by Lance Olenik, 2/5/2020) */


          var countResults = 0;
          dataResponse.aggregations.all_docs.doc_type.buckets.forEach(function (bucket) {
            countResults += bucket.doc_count;
          });
          dataLayer.push({
            event: 'userEvent',
            eC: 'Internal Search Results',
            eA: countResults.toString(),
            eL: $scope.searchTerm
          });
          /* End Google Tags */

          parseResults(dataResponse, shouldUpdateDocTypes);
        });
      };

      function updateURLParameter(url, param, paramVal) {
        var newAdditionalURL = '';
        var tempArray = url.split('?');
        var baseURL = tempArray[0];
        var additionalURL = tempArray[1];
        var temp = '';

        if (additionalURL) {
          tempArray = additionalURL.split('&');

          for (var i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] != param) {
              newAdditionalURL += temp + tempArray[i];
              temp = '&';
            }
          }
        }

        var rows_txt = temp + '' + param + '=' + paramVal;
        return baseURL + '?' + newAdditionalURL + rows_txt;
      }
      /**
       * When the 'search the product store' button is clicked
       * redirect to NSE product search with the appropriate
       * search term.
       */


      $scope.newProductSearch = function () {
        var _window$location$host;

        console.log('Redirecting to NSE product to search for: ' + $scope.searchTerm);
        window.history.replaceState('', '', updateURLParameter(window.location.href, 'q', $scope.searchTerm)); // in case someone clicks the 'back' button, this should be updated to the most recent search term.
        // get country and environment info for linking to NSE

        var _ref = (_window$location$host = window.location.host.match(/(\w{2})(\w{2})web/)) !== null && _window$location$host !== void 0 ? _window$location$host : [null, null, null],
            _ref2 = _slicedToArray(_ref, 3),
            _ = _ref2[0],
            env = _ref2[1],
            country = _ref2[2];

        if (env) {
          switch (env.toLowerCase()) {
            case "qa":
              window.location.href = "https://productstore-".concat('qa' + country, ".melaleuca.com/search?searchTerm=") + $scope.searchTerm;
              break;

            case "ua":
              window.location.href = "https://productstore-".concat('uat' + country, ".melaleuca.com/search?searchTerm=") + $scope.searchTerm;
              break;

            case "st":
              window.location.href = "https://productstore-".concat(country + '-preview', ".melaleuca.com/search?searchTerm=") + $scope.searchTerm;
              break;

            default:
              window.location.href = "".concat($scope.cultureConfig.NSELink, "/search?searchTerm=") + $scope.searchTerm;
          }
        } else {
          window.location.href = "".concat($scope.cultureConfig.NSELink, "/search?searchTerm=") + $scope.searchTerm;
        }
      };
      /**
       * Reset category filter
       */


      $scope.clearCategory = function () {
        searchSync.updateQuery($scope.searchTerm, '');
      };
      /**
       * Get search result data from search API
       * @param  {Function} callbackFunc - Callback to handle response data
       */


      var fetchResults = function fetchResults(callbackFunc) {
        dataService.getSearchResults(getQueryParams()).success(function (data) {
          if (data.error) {
            console.log(data.error);
            $scope.loading = false;
          } else {
            callbackFunc(data);
          }
        }).error(function (data, status, headers) {
          $scope.loading = false;
          console.log('Error fetching results: ', data);
        });
      };
      /**
       * Parses search results, updating necessary scope variables
       *
       * @param {Array} results -
       */


      var parseResults = function parseResults(results, shouldUpdateDocTypes) {
        shouldUpdateDocTypes = shouldUpdateDocTypes || false; // Fetch other search types, if signed in

        if ($scope.signedIn) {
          var buckets = results.aggregations.all_docs.doc_type.buckets.filter(function (obj) {
            return obj.key !== 'product'; // remove the 'product' bucket (now handled on NSE)
          });
          var productsIndex = buckets.findIndex(function (bucket) {
            return bucket.key === 'web page';
          });
          $scope.docTypes = buckets;

          if (buckets[0] && (shouldUpdateDocTypes || !$scope.docTypes.length)) {
            $scope.docType = buckets[0].key;
          }
        } // Has results--parse them


        if (results.hits.total !== 0) {
          $scope.resultCount = results.hits.total;
          var resultsArray = results.hits.hits.map(function (result) {
            return wmUtil.formatFields(result._source, $scope.docType);
          });
          $scope.products = $scope.products.concat(resultsArray);
          $scope.loading = false; // **************************
          // PRODUCTS: Get list of
          // inventory-controlled SKUs
          // **************************

          var skuList = resultsArray.filter(function (result) {
            return result.is_inventory_controlled;
          }).map(function (result) {
            return result.sku;
          }); // Check inventory status, then update data

          if (skuList.length) {
            dataService.checkInventory(skuList, $scope.products, $scope);
          } // **************************
          // VIDEOS: Get video info
          // using mediaId
          // **************************


          var videoList = resultsArray.filter(function (result) {
            return result.media_id;
          }).map(function (result) {
            return result.media_id;
          });

          if (videoList.length) {
            wmUtil.setVideoData(videoList);
          } // Loading new results--create page view tag


          if ($scope.from === 0) {
            createPageviewTag();
          } // Typo already registered--clear autocorrect term


          if ($scope.hasTypo) {
            $scope.didYouMean = '';
          }
        } else if ($scope.signedIn && $scope.docTypes.length === 1 || !$scope.signedIn) {
          // No product or other results--get autocorrection
          getDidYouMean(parseDidYouMean);
        } else {
          // No product results, but other results available--stop loading.
          $scope.loading = false;
        }
      };

      var parseDidYouMean = function parseDidYouMean(response) {
        // Update original spelling if didYouMean has not been populated
        if (!$scope.didYouMean.length) {
          $scope.originalSpelling = response.suggest.simple_phrase[0].text;
        }

        if (response.suggest.simple_phrase[0].options.length) {
          $scope.didYouMean = response.suggest.simple_phrase[0].options[0].text;
          searchSync.updateQuery($scope.didYouMean, $scope.category);
        } else {
          $scope.didYouMean = '';
          $scope.loading = false;
        }
      };

      var getDidYouMean = function getDidYouMean(callbackFunc) {
        dataService.getSearchResults({
          id: 'did_you_mean',
          index: wmUtil.searchIndex,
          params: {
            searchTerm: $scope.searchTerm
          }
        }).success(function (data) {
          if (data.error) {
            console.log(data.error);
          } else {
            callbackFunc(data);
          }
        }).error(function (data, status, headers) {
          console.log('Error getting Did You Mean suggestions: ', headers);
        });
      };
      /**
       * Creates request parameters object for Elasticsearch
       *
       * @return {Object}          [description]
       */


      var getQueryParams = function getQueryParams() {
        var searchTerm = wmUtil.replaceFullWidthNumerals($scope.searchTerm);
        var reqParams = {
          id: 'results',
          index: wmUtil.searchIndex,
          params: {
            size: $scope.size,
            from: $scope.from,
            docType: $scope.docType,
            sortField: $interpolate($scope.sortBy.sortField)($scope),
            sortDir: $scope.sortBy.sortDir
          }
        };

        if (wmUtil.pointsRegex.test(searchTerm)) {
          reqParams.params.points = searchTerm.match(wmUtil.pointsRegex)[1];
        } else if (isNaN(searchTerm)) {
          reqParams.params.searchTerm = searchTerm;
        } else {
          reqParams.params.sku = searchTerm;
        } // Product-specific filters


        if ($scope.docType === 'product') {
          reqParams.params.channelId = 1;

          if (wmUtil.isDefined($scope.category)) {
            reqParams.params.category = $scope.category;
          }
        }

        return reqParams;
      };
      /**
       * Create page view tag after results have loaded
       */


      var createPageviewTag = function createPageviewTag() {
        var clientId = $window.PageClientData.Id || 'Guest';
        var displayMode = isMobileSite ? 'Mobile' : 'Desktop';
        window.cmCreatePageviewTag('Search Results Page', 'Site Search - Results', $scope.searchTerm, $scope.resultCount, clientId + '-_-' + PageClientData.UICulture + '-_-' + $scope.category + '-_--_--_--_-' + displayMode);
      };
    }]);
    angular.bootstrap(document.getElementById('search-results'), ['searchResults', 'sharedService', 'ngWmUtil']);
  })();

}());
